import { useQuery } from "react-query";
import { getNotifySubscriptionGroups, GetNotifySubscriptionGroupsParamsType } from "../../../queries/notify";
import useNetworkHeaders from "../../permits/components/InspectionsCard/useNetworkHeaders";

export function useNotifySubscriptionGroups(params: GetNotifySubscriptionGroupsParamsType) {
  const networkHeaders = useNetworkHeaders();
  const {
    data: subscriptionGroups,
    isLoading: isSubscriptionGroupsLoading,
    isFetching: isSubscriptionGroupsFetching,
  } = useQuery(buildNotifySubscriptionGroupsQueryKey(params), getNotifySubscriptionGroups(networkHeaders), {
    queryFnParamsFilter(args) {
      return [args[1]];
    },
    keepPreviousData: true,
  });

  return { subscriptionGroups, isSubscriptionGroupsLoading, isSubscriptionGroupsFetching };
}

export function buildNotifySubscriptionGroupsQueryKey(
  params?: GetNotifySubscriptionGroupsParamsType | { id: number | null },
) {
  if (!params) {
    return "notifySubscriptionGroups";
  }
  return ["notifySubscriptionGroups", params];
}
