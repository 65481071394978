import React from "react";
import { ProductType, ProductRoutesMap } from "../constants/products";
import { fetchGetProducts, hasProduct } from "../queries/products";
import { Redirect, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import identitySelectors from "../selectors/identity";
import { Dimmer, Loader } from "semantic-ui-react";

/**
 * Redirects user if they are not logged in or the product is not available
 * If user is logged in but product is not available or user does not have permission,
 * hook will redirect to first product that is available
 */
export default function useEnsureCanUseProduct({
  products,
  permissions,
}: { products?: ProductType[] | ProductType; permissions?: (state: any) => any } = {}) {
  const params = useParams<{ site: string }>();
  const dispatch = useDispatch();
  const productsQuery = useQuery(["products", params.site], fetchGetProducts(dispatch));
  const isLoggedIn = useSelector(identitySelectors.isLoggedIn);
  const hasPermission = permissions ? useSelector(permissions) : true;

  const productsIsArrayOrUndefined = Array.isArray(products) || products === undefined;
  const productsArray = productsIsArrayOrUndefined ? products : [products];
  const hasProductOnAllowedList = productsArray?.some((product) => hasProduct(productsQuery, product)) ?? false;

  const loggedOutRedirect = isLoggedIn ? null : (
    <Redirect to={`/${params.site}?redirect=${encodeURIComponent(window.location.href)}`} />
  );

  const loader = productsQuery.isLoading ? (
    <Dimmer active>
      <Loader />
    </Dimmer>
  ) : null;

  let productRoute: string | null = null;

  if (!hasProductOnAllowedList || !hasPermission) {
    ProductRoutesMap.forEach((route, product) => {
      if (!productRoute && hasProduct(productsQuery, product)) {
        productRoute = route;
      }
    });
  }

  const productRedirect = productRoute ? <Redirect to={`/${params.site}/${productRoute}`} /> : null;

  return {
    loggedOutRedirect,
    loader,
    productRedirect,
  };
}
