import { isValid, parse, parseISO } from "date-fns";
import _ from "lodash";

export const toDate = (input: any): Date | null => {
  if (_.isDate(input)) return input;
  if (_.isEmpty(input)) return null;

  let ret = null;

  try {
    const inputString = input.toString();
    let date = null;
    const usFormatReg = /^\d{1,2}\/\d{1,2}\/\d{2,4}$/; // mm/dd/yyyy
    const isoFormatReg = /\d{4}-\d{1,2}-\d{1,2}/;
    if (usFormatReg.test(inputString)) {
      date = parse(inputString, "MM/dd/yyyy", new Date());
    } else if (isoFormatReg.test(inputString)) {
      date = parseISO(inputString);
    }

    if (isValid(date)) {
      ret = date;
    } else {
      console.log("Unable to parse valid date from input: " + input);
    }
  } catch (error) {
    console.log("Error while converting input to date: " + input);
  }

  return ret;
};
