import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useMedia } from "react-media";
import { useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Header,
  Icon,
  Loader,
  Popup,
  Segment,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import TopNavigation from "../../../../../components/global_components/TopNavigation";
import identity from "../../../../../selectors/identity";
import { pluralize } from "../../../../../utils/pluralize";
import { NewSubscriptionGroupFlyout } from "./flyouts/NewSubscriptionGroupFlyout";
import { useNotifySubscriptionGroups } from "../../../hooks/useNotifySubscriptionGroups";
import { EditSubscriptionGroupFlyout } from "./flyouts/EditSubscriptionGroupFlyout";
import { useFlyout } from "../../../../../components/global_components/Flyout";
import { useToasts } from "react-toast-notifications";
import { useNotifySubscriptionGroupReactivate } from "../../../hooks/useNotifySubscriptionGroupReactivate";

export function SubscriptionGroupsPage() {
  const toast = useToasts();
  const isSmallScreen = useMedia({ query: "(max-width: 768px)" });
  const isCommunicationsAdmin = useSelector(identity.isCommunicationsAdmin);

  const [includeDeactivated, setIncludeDeactivated] = useState(false);
  const { isOpen: isCreateGroupOpen, open: openCreateGroup, close: closeCreateGroup } = useFlyout();
  const { id: editGroupId, openWithId: openEditGroupWithId, close: closeEditGroup } = useFlyout();

  const { subscriptionGroups, isSubscriptionGroupsLoading, isSubscriptionGroupsFetching } = useNotifySubscriptionGroups(
    {
      includeDeactivated,
    },
  );

  const { reactivateSubscriptionGroup, isReactivateSubscriptionGroupLoading } = useNotifySubscriptionGroupReactivate({
    onSuccess: () => {
      toast.addToast("Subscription group reactivated", { appearance: "success", autoDismiss: true });
    },
    onError: () => {
      toast.addToast("Failed to reactivate subscription group", { appearance: "error", autoDismiss: true });
    },
  });

  const chooseAlignment = (preferred: "center" | "left" | "right") => {
    return isSmallScreen ? "left" : preferred;
  };

  return (
    <div>
      <NewSubscriptionGroupFlyout open={isCreateGroupOpen} onClose={closeCreateGroup} />
      <EditSubscriptionGroupFlyout id={editGroupId} onClose={closeEditGroup} />
      <TopNavigation
        tabs={[
          {
            label: "Notifications",
            activeWhenURLIncludes: "/notify/notifications",
            to: "/communications",
          },
          {
            label: "Subscribers",
            activeWhenURLIncludes: "/notify/subscribers",
            to: "/notify/subscribers",
          },
          {
            label: "Subscription Groups",
            activeWhenURLIncludes: "/notify/subscription-groups",
            to: "/notify/subscription-groups",
          },
        ]}
      />
      <div className='tw-p-3 sm:tw-p-6'>
        <Segment className='tw-flex tw-flex-col tw-justify-between tw-gap-3 tw-p-6 sm:tw-flex-row sm:tw-items-center'>
          <Header className='tw-mb-0' size='large'>
            Subscription Groups
          </Header>
          <div>
            <Button
              className='tw-mr-0'
              primary
              content='New Subscription Group'
              onClick={openCreateGroup}
              disabled={!isCommunicationsAdmin}
            />
          </div>
        </Segment>

        <Segment loading={isSubscriptionGroupsLoading} basic className='tw-overflow-x-auto tw-p-0 tw-pl-[1px]'>
          <Segment attached='top' className='tw-flex tw-items-center tw-justify-between tw-px-[11px] tw-py-1'>
            <span className='subText tw-flex tw-items-center tw-gap-2'>
              {subscriptionGroups?.length} Subscription {pluralize(subscriptionGroups?.length, "Group", "Groups")}
              <Loader inline active={isSubscriptionGroupsFetching && !isSubscriptionGroupsLoading} size='tiny' />
            </span>
            <Checkbox
              toggle
              label='Show deactivated groups'
              checked={includeDeactivated}
              onChange={() => setIncludeDeactivated((prev) => !prev)}
            />
          </Segment>
          <Table attached='bottom' className='tw-shadow-none'>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Visibility</TableHeaderCell>
                <TableHeaderCell className='tw-whitespace-nowrap sm:tw-w-52' textAlign={chooseAlignment("center")}>
                  Auto Subscribe
                </TableHeaderCell>
                <TableHeaderCell textAlign={chooseAlignment("right")}>Subscribers</TableHeaderCell>
                <TableHeaderCell className='sm:tw-w-52 lg:tw-w-72' textAlign={chooseAlignment("right")}>
                  Actions
                </TableHeaderCell>
              </TableRow>
            </TableHeader>

            <TableBody>
              {!subscriptionGroups?.length && (
                <TableRow>
                  <TableCell colSpan={5} textAlign='center'>
                    No subscription groups found
                  </TableCell>
                </TableRow>
              )}
              {subscriptionGroups?.map((row) => {
                const isDeactivated = !!row.deactivatedAt;
                return (
                  <TableRow
                    className='tw-cursor-pointer hover:tw-bg-white-hover'
                    key={row.id}
                    error={isDeactivated}
                    onClick={() => openEditGroupWithId(row.id)}
                  >
                    <TableCell className='tw-max-w-sm tw-overflow-hidden'>
                      <Header as='h4' className='tw-mb-0'>
                        <div className='ellipses-overflow tw-whitespace-nowrap'>{row.name}</div>
                        <Header.Subheader className='ellipses-overflow tw-whitespace-nowrap'>
                          {row.description}
                        </Header.Subheader>
                      </Header>
                    </TableCell>
                    <TableCell collapsing>{row.visibility}</TableCell>
                    <TableCell collapsing textAlign={chooseAlignment("center")}>
                      {row.autoSubscribe && <FontAwesomeIcon icon={faCheck} />}
                    </TableCell>
                    <TableCell textAlign={chooseAlignment("right")} collapsing>
                      0 {/* TODO: Add subscriber count */}
                    </TableCell>
                    <TableCell className='tw-flex tw-w-full tw-justify-end tw-gap-1' collapsing>
                      {isDeactivated && (
                        <Button
                          className='button-ghost negative tw-mr-0'
                          onClick={(event) => {
                            event.stopPropagation();
                            reactivateSubscriptionGroup(row.id);
                          }}
                          loading={isReactivateSubscriptionGroupLoading}
                          disabled={isReactivateSubscriptionGroupLoading}
                        >
                          <Icon name='undo' />
                          <span>Reactivate</span>
                        </Button>
                      )}
                      {!isDeactivated && (
                        <>
                          <Popup
                            hoverable
                            size='small'
                            inverted
                            content='Send invites'
                            trigger={
                              <Button
                                className='button-ghost tw-mr-0  tw-p-3'
                                size='large'
                                onClick={(event) => {
                                  event.stopPropagation();
                                  console.log("SEND INVITES");
                                }}
                              >
                                <i className='fa-kit fa-regular-envelope-circle-plus'></i>
                              </Button>
                            }
                          />
                          <Popup
                            hoverable
                            size='small'
                            inverted
                            content='Manage subscribers'
                            trigger={
                              <Button
                                className='button-ghost tw-mr-0 tw-p-3'
                                size='large'
                                onClick={(event) => {
                                  event.stopPropagation();
                                  console.log("MANAGE SUBSCRIBERS");
                                }}
                              >
                                <FontAwesomeIcon size='1x' icon={faUsers} />
                              </Button>
                            }
                          />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Segment>
      </div>
    </div>
  );
}
