import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { FocusScope } from "react-aria";
import { Segment, Sidebar } from "semantic-ui-react";
import { useDebounce } from "../../hooks/useDebounce";
import { useToasts } from "react-toast-notifications";

export function Flyout({
  children,
  open,
  onClose = () => {},
}: PropsWithChildren<{ open?: boolean; onClose?: () => void }>) {
  const toast = useToasts();

  const handleEscape = useCallback((event) => {
    if (event.key === "Escape") {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleEscape, false);

    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape]);

  if (open) {
    toast.removeAllToasts();
  }

  return (
    <FocusScope contain restoreFocus autoFocus>
      <Sidebar
        animation='overlay'
        direction='right'
        vertical='true'
        visible={open}
        onHide={onClose}
        // The sidebar is 2.5rem wider than the segment to help prevent accidental clicks outside the sidebar
        className='tw-flex tw-w-full tw-justify-end tw-shadow-none tw-duration-300 sm:tw-w-[38.5rem]'
      >
        <Segment className='tw-flex tw-h-full tw-w-full tw-flex-col tw-overflow-hidden tw-rounded-none tw-border-y-0 tw-border-r-0 tw-p-0 tw-drop-shadow-lg sm:tw-w-[36rem]'>
          {children}
        </Segment>
      </Sidebar>
    </FocusScope>
  );
}

Flyout.Header = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Segment className='tw-my-0 tw-flex tw-min-h-[4.5rem] tw-items-center tw-justify-between tw-rounded-none tw-border-x-0 tw-border-t-0'>
      {children}
    </Segment>
  );
};

Flyout.Body = ({ children, isLoading }: PropsWithChildren<{ isLoading?: boolean }>) => {
  return (
    <Segment loading={isLoading} basic className='tw-my-0 tw-h-full tw-w-full tw-overflow-y-auto'>
      {children}
    </Segment>
  );
};

Flyout.Footer = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Segment className='tw-my-0 tw-min-h-[4.5rem] tw-w-full tw-rounded-none tw-border-x-0 tw-border-b-0 tw-bg-white-background'>
      {children}
    </Segment>
  );
};

export function useFlyout({
  onOpen = () => {},
  onClose = () => {},
}: { onOpen?: () => void; onClose?: () => void } = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<number | null>(null);

  // If the user clicks on another record while the flyout is open, triggering the "click outside" onClose event,
  // we want to ignore the onClose event and instead update the flyout with the new record's data.
  const debouncedId = useDebounce(id, 100);
  const didSwap = debouncedId !== id;

  const close = useCallback(() => {
    if (didSwap) return;
    setIsOpen(false);
    setId(null);
    onClose();
  }, [didSwap, setIsOpen, setId]);

  const open = useCallback(() => {
    setIsOpen(true);
    onOpen();
  }, [setIsOpen]);

  const openWithId = useCallback(
    (id: number) => {
      setId(id);
      open();
      onOpen();
    },
    [setId, open],
  );

  return { isOpen, id, open, openWithId, close };
}
