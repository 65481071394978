import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { Form, Button, Header } from "semantic-ui-react";
import { ControlledInput, ControlledTextArea, ControlledRadioGroup } from "../../../../../../components/ControlledForm";
import { Flyout } from "../../../../../../components/global_components/Flyout";
import { useNotifySubscriptionGroupCreate } from "../../../../hooks/useNotifySubscriptionGroupCreate";
import Tooltip from "../../../../../../components/Tooltip";

type NewSubscriptionGroupForm = {
  name: string;
  description: string;
  visibility: "Public" | "Private" | "Unlisted";
  autoSubscribe: "Yes" | "No";
};

export function NewSubscriptionGroupFlyout({ open, onClose }: { open: boolean; onClose: () => void }) {
  const toast = useToasts();

  const { control, errors, handleSubmit, reset } = useForm<NewSubscriptionGroupForm>({
    defaultValues: {
      name: "",
      description: "",
      visibility: "Public",
      autoSubscribe: "Yes",
    },
  });

  const { createSubscriptionGroup, isCreateSubscriptionGroupLoading } = useNotifySubscriptionGroupCreate({
    onSuccess: () => {
      reset();
      onClose();
      toast.addToast("Subscription group created", { appearance: "success", autoDismiss: true });
    },
    onError: () => {
      toast.addToast("Failed to create subscription group", { appearance: "error", autoDismiss: true });
    },
  });

  const onSave: SubmitHandler<NewSubscriptionGroupForm> = (data) => {
    createSubscriptionGroup({
      name: data.name.trim(),
      description: data.description.trim(),
      visibility: data.visibility,
      autoSubscribe: data.autoSubscribe === "Yes",
    });
  };

  return (
    <Flyout open={open} onClose={onClose}>
      <Flyout.Header>
        <Header className='tw-mb-0'>New Subscription Group</Header>
      </Flyout.Header>
      <Flyout.Body>
        <Form>
          <Form.Field required error={!!errors["name"]}>
            <ControlledInput
              control={control}
              name='name'
              label='Name'
              errors={errors}
              rules={{
                validate: {
                  required: (value: string) => {
                    if (!value.trim()) {
                      return "Name is required";
                    }
                    return true;
                  },
                  maxLength: (value: string) => {
                    if (value.trim().length > 191) {
                      return "Name cannot be longer than 191 characters";
                    }
                    return true;
                  },
                },
              }}
            />
          </Form.Field>
          <Form.Field error={!!errors["description"]}>
            <ControlledTextArea
              control={control}
              name='description'
              label='Description'
              rows={3}
              errors={errors}
              rules={{
                validate: {
                  maxLength: (value: string) => {
                    if (value.trim().length > 191) {
                      return "Description cannot be longer than 191 characters";
                    }
                    return true;
                  },
                },
              }}
            />
          </Form.Field>
          <Form.Field error={!!errors["visibility"]}>
            <label>
              Visibility
              <Tooltip>
                Private & Unlisted groups will not be visible on the public portal. Subscribers must be manually added
                by staff or a private subscribe link. <br /> Subscribers must log in to the app or website to view the
                list of notifications posted to private groups, but individual message links can be shared with anyone.
              </Tooltip>
            </label>
            <ControlledRadioGroup
              control={control}
              name='visibility'
              options={[
                { key: "Public", text: "Public", value: "Public" },
                { key: "Unlisted", text: "Unlisted", value: "Unlisted" },
                { key: "Private", text: "Private", value: "Private" },
              ]}
              direction='horizontal'
            />
          </Form.Field>
          <Form.Field error={!!errors["description"]}>
            <label>
              Auto Subscribe
              <Tooltip>
                All subscribers, including anonymous contacts, will be added to this group by default. Anonymous
                subscribers will only receive messages from Auto Subscribe groups.
              </Tooltip>
            </label>
            <ControlledRadioGroup
              control={control}
              name='autoSubscribe'
              options={[
                { key: "Yes", text: "Yes", value: "Yes" },
                { key: "No", text: "No", value: "No" },
              ]}
              direction='horizontal'
            />
          </Form.Field>
        </Form>
      </Flyout.Body>
      <Flyout.Footer>
        <div className='tw-flex tw-justify-between'>
          <Button className='button-ghost' content='Close' onClick={onClose} />
          <Button
            primary
            content='Save'
            onClick={handleSubmit(onSave)}
            disabled={isCreateSubscriptionGroupLoading}
            loading={isCreateSubscriptionGroupLoading}
          />
        </div>
      </Flyout.Footer>
    </Flyout>
  );
}
