import React from "react";
import Moment, { MomentProps } from "react-moment";

/**
 * @deprecated Use ZonedDateTime instead
 * @param props
 * @returns
 */
export const SafeMoment = (props: MomentProps & { children: MomentProps["children"] | null }) => {
  if ([null, "", "0000-00-00 00:00:00"].includes(props.children as string | null)) {
    return <></>;
  }
  return <Moment {...props}>{props.children}</Moment>;
};
