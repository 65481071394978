import React from "react";
import { Popup, Button } from "semantic-ui-react";

export const Tooltip = (props: any) => {
  return (
    <div className='tw-ml-1 tw-inline-block'>
      <Popup
        hoverable
        size='small'
        inverted
        trigger={
          <div>
            <Button
              aria-labelledby='moreInfoLabelTooltip'
              icon='question circle outline'
              className='tw-border-none tw-bg-transparent tw-p-1 hover:tw-bg-[#cacbcd]'
              type='button'
              aria-describedby='tooltipPopupContent'
            />
            <span id='moreInfoLabelTooltip' aria-hidden='true' className='sr-only'>
              More info
            </span>
          </div>
        }
        on={["hover", "click"]}
        closeOnTriggerBlur
        closeOnTriggerClick={false}
      >
        <Popup.Content id='tooltipPopupContent' aria-live='polite'>
          {props.children}
        </Popup.Content>
      </Popup>
    </div>
  );
};

export default Tooltip;
