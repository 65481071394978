import React, { useRef, useState, useEffect } from "react";
import {
  Checkbox,
  Popup,
  Icon,
  Input,
  CheckboxProps,
  Table,
  Divider,
  Button,
  Modal,
  Form,
  Segment,
  Radio,
} from "semantic-ui-react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { ControlledInput } from "../../../components/ControlledForm";

import "./DropDownFilter.scss";
import { ControlledCurrency } from "../../../components/ControlledCurrency";
import { yupResolver } from "@hookform/resolvers";
import PermitsFees from "../../../schemas/PermitsFees";

const FilterDiv = styled.div`
  &&&& {
    padding: 9px 10px;
    background: #ffffff;
    border: 1px solid #dbddde;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
    }
    .description-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-right: 30px;
      .description-text {
        color: gray;
      }
    }
  }
`;
const FilterContent = styled.div`
  &&&& {
    background: #ffffff;
    border: 1px solid #dbddde;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .search {
      border: none;
    }
    .selectContainer {
      padding: 9px 10px;
      padding: 9px 10px;
      display: flex;
      flex-direction: column;
      max-height: 500px;
      overflow: scroll;
      &.description {
        width: 400px;
      }
      .sub-title {
        margin: 7px 0;
      }
      .smallCaption {
        margin-top: 16px;
        margin-bottom: 4px;
      }
      .title-clear {
        margin-bottom: 5px;
      }
      .title-all {
        margin-bottom: 5px;
        margin-top: 15px;
      }
      .checkbox {
        min-height: auto;
        padding: 5px 0px;
        border-radius: 2px;
        :hover {
          background-color: #f4f5f7;
        }
        label {
          min-height: 17px;
        }
      }
      .checkbox-description-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          color: gray;
        }
      }
      button {
        height: 40px;
        min-height: unset;
      }
    }
  }
`;

export const DropDownFilter = (props: any) => {
  const { options, value, title, onChange, defaultTitle = "All" } = props;
  const contextRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const selected = options.filter((option: any) => value?.includes(option.value));
  const selectedText = selected.map((el: any) => el.text.charAt(0).toUpperCase() + el.text.slice(1));
  let filteredOptions = options.filter((option: any) => option.text.toLowerCase().includes(searchText.toLowerCase()));

  const onChangeCheckbox = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps, optionValue: string) => {
    let tempValue = [...value];
    if (!data.checked) {
      tempValue = tempValue.filter((el: any) => el !== optionValue);
    } else {
      tempValue.push(optionValue);
    }
    let tempTitle = title;
    if (title === "assigned To") {
      tempTitle = "assignee_ids";
    }
    onChange("", tempValue, tempTitle);
  };

  const getText = () => {
    let str = selectedText.length > 0 ? selectedText.join(", ") : defaultTitle;
    if (str?.length > 30) {
      str = str.substring(0, 27) + "...";
    }
    return str;
  };

  const getCapital = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (popupRef && popupRef.current) {
        const ref: any = popupRef.current;
        if (contextRef.current?.contains(e.target)) return;
        if (!ref.contains(e.target)) {
          setOpen(false);
        }
      }
    }
  }, []);

  if (title === "types") {
    filteredOptions = filteredOptions?.sort((a: any, b: any) => {
      if (a.key.toLowerCase() < b.key.toLowerCase()) {
        return -1;
      }
      if (a.key.toLowerCase() > b.key.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <>
      <FilterDiv ref={contextRef} onClick={() => setOpen((prevOpen) => !prevOpen)}>
        <p>{getText()}</p>
        <Icon name='caret down' />
      </FilterDiv>
      <Popup
        className='filter-dropdown'
        context={contextRef}
        open={open}
        position='top left'
        basic
        style={{ padding: 0 }}
      >
        <FilterContent ref={popupRef}>
          <Input
            className='search'
            icon='search'
            placeholder={`Search`}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
          <div className='selectContainer'>
            <p className='title-clear'>Clear selected {title}</p>
            {filteredOptions?.map(
              (option: any, id: number) =>
                value?.includes(option.value) && (
                  <Checkbox
                    key={id}
                    label={<label>{getCapital(option.text)}</label>}
                    checked={value?.includes(option.value)}
                    onChange={(e, data) => onChangeCheckbox(e, data, option.value)}
                  />
                ),
            )}
            <p className='title-all'>All {title}</p>
            {filteredOptions?.map(
              (option: any, id: number) =>
                !value?.includes(option.value) && (
                  <Checkbox
                    key={id}
                    label={<label>{getCapital(option.text)}</label>}
                    checked={value?.includes(option.value)}
                    onChange={(e, data) => onChangeCheckbox(e, data, option.value)}
                  />
                ),
            )}
          </div>
        </FilterContent>
      </Popup>
    </>
  );
};

export const DropDownSelector = (props: any) => {
  const { options, value, title, onChange, singleSelect, defaultTitle = "All" } = props;
  const contextRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLButtonElement>(null);
  const inputReference = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const selected = options.filter((option: any) => value == option.value);
  let filteredOptions = options.filter((option: any) => option.text.toLowerCase().includes(searchText.toLowerCase()));

  const onChangeCheckbox = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps, optionValue: string) => {
    onChange(optionValue);
  };

  const getText = () => {
    let str = selected.length === 0 ? defaultTitle : selected[0].text;
    if (str?.length > 30) {
      str = str.substring(0, 27) + "...";
    }
    return str;
  };

  const getCapital = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (open) {
      const ref: any = inputReference.current;
      ref?.focus();
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (popupRef && popupRef.current) {
        const ref: any = popupRef.current;
        if (contextRef.current?.contains(e.target)) return;
        if (!ref.contains(e.target)) {
          setOpen(false);
        }
      }
    }
  }, []);

  if (title === "types") {
    filteredOptions = filteredOptions?.sort((a: any, b: any) => {
      if (a.key.toLowerCase() < b.key.toLowerCase()) {
        return -1;
      }
      if (a.key.toLowerCase() > b.key.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <>
      <FilterDiv ref={contextRef} onClick={() => setOpen((prevOpen) => !prevOpen)}>
        <p className={selected.length ? "normal" : "hint"}>{getText()}</p>
        <Icon name='caret down' />
      </FilterDiv>
      <Popup
        className='filter-dropdown'
        context={contextRef}
        open={open}
        position='bottom right'
        basic
        style={{ padding: 0 }}
      >
        <FilterContent ref={popupRef}>
          <Input
            ref={inputReference}
            className='search'
            icon='search'
            placeholder={`Find ${title}`}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
          <div className='selectContainer'>
            {filteredOptions?.map((option: any, id: number) => (
              <Checkbox
                radio={singleSelect}
                key={id}
                label={<label>{getCapital(option.text)}</label>}
                checked={value == option.value}
                onChange={(e, data) => onChangeCheckbox(e, data, option.value)}
              />
            ))}
          </div>
        </FilterContent>
      </Popup>
    </>
  );
};

export const DropDownRowSelector = (props: any) => {
  const { options, value, title, onChange, defaultTitle, onChangeFilter } = props;
  const inputReference = useRef(null);
  const contextRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const selected = options.filter((option: any) => value == option.value);

  const handleClick = (id: any) => {
    onChange(id);
    setOpen(false);
  };

  const getText = () => {
    let str = selected.length === 0 ? defaultTitle : `${selected[0]["first-name"]} ${selected[0]["last-name"]}`;
    if (str?.length > 30) {
      str = str.substring(0, 27) + "...";
    }
    return str;
  };

  useEffect(() => {
    if (open) {
      const ref: any = inputReference.current;
      ref?.focus();
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (popupRef && popupRef.current) {
        const ref: any = popupRef.current;
        if (contextRef.current?.contains(e.target)) return;
        if (!ref.contains(e.target)) {
          setOpen(false);
        }
      }
    }
  }, []);

  return (
    <>
      <FilterDiv ref={contextRef} onClick={() => setOpen((prevOpen) => !prevOpen)}>
        <p style={{ color: selected.length === 0 ? "rgba(0, 0, 0, 0.5)" : "inherit" }}>{getText()}</p>
        <Icon name='caret down' />
      </FilterDiv>
      <Popup
        className='filter-dropdown'
        context={contextRef}
        open={open}
        position='bottom left'
        wide='very'
        basic
        style={{ width: 562, padding: 0 }}
      >
        <FilterContent ref={popupRef}>
          <Input
            className='search'
            ref={inputReference}
            icon='search'
            placeholder={`Find ${title}`}
            onChange={(e) => {
              onChangeFilter(e.target.value);
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <div className='selectContainer'>
            <Table singleLine celled selectable className='workflow-table dropdown-table' name='defaultWorkflowSteps'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>First Name</Table.HeaderCell>
                  <Table.HeaderCell>Last Name</Table.HeaderCell>
                  <Table.HeaderCell>Address</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {options?.map((item: any, index: number) => (
                  <Table.Row
                    key={index}
                    onClick={() => handleClick(item.value)}
                    className={value === item.value ? "active" : ""}
                  >
                    <Table.Cell>{item["first-name"]}</Table.Cell>
                    <Table.Cell>{item["last-name"]}</Table.Cell>
                    <Table.Cell>{item.address}</Table.Cell>
                    <Table.Cell>{item.email}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </FilterContent>
      </Popup>
    </>
  );
};

const AddFeeModal = ({ onSubmit, open, setOpen }: any) => {
  const formContext = useForm({
    mode: "onSubmit",
    defaultValues: {
      "name": "",
      "fee-type": "fee",
      "default-amount": 0,
    },
    resolver: yupResolver(PermitsFees),
  });
  const { control, handleSubmit, errors } = formContext;

  return (
    <Modal
      as={Form}
      header='Create New Item'
      scrolling={true}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='tiny'
      className='add-name-modal'
      content={
        <>
          <Segment basic>
            <div style={{ marginBottom: 16 }}>
              <Form.Field>
                <Controller
                  name='fee-type'
                  control={control}
                  defaultValue={"fee"}
                  render={({ onChange, value }) => {
                    return (
                      <div className='ControlledRadioSelect flex-row'>
                        <Radio label='Fee' value='fee' checked={value === "fee"} onChange={() => onChange("fee")} />
                        <Radio
                          label='Discount'
                          value='waive'
                          checked={value === "waive"}
                          onChange={() => onChange("waive")}
                        />
                      </div>
                    );
                  }}
                />
              </Form.Field>
              <Form.Field required>
                <label htmlFor='name'>Name</label>
                <ControlledInput name='name' control={control} errors={errors} />
              </Form.Field>
              <Form.Field>
                <label htmlFor='default-amount'>Default Amount</label>
                <ControlledCurrency control={control} name='default-amount' errors={errors} />
              </Form.Field>
              <Form.Field>
                <div className='tw-mb-3 tw-mt-6 tw-flex'>
                  <label
                    className='tw-text-[.92857143em] tw-font-bold tw-normal-case tw-text-[rgba(0,_0,_0,_.87)]'
                    htmlFor='general-ledger'
                  >
                    General Ledger Account
                  </label>
                  <Popup
                    trigger={<Button icon='question circle outline' className='icon-only-button' type='button' />}
                    hoverable
                    content={
                      <Segment className='tw-w-96' basic>
                        {
                          "You can include the Account number and Description if you want and that will be displayed on reports, but be sure you enter it exactly the same on all the fee items that share this account."
                        }
                      </Segment>
                    }
                    position='top center'
                  />
                </div>
                <ControlledInput control={control} name='general-ledger' errors={errors} />
              </Form.Field>
            </div>
            <div></div>
          </Segment>
          <div className='actions'>
            <Button
              secondary
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button primary onClick={handleSubmit(onSubmit)}>
              Add
            </Button>
          </div>
        </>
      }
    />
  );
};

export const DropDownMultiTypeSelector = (props: any) => {
  const { fees, waives, createFeeitem, value, onChange, singleSelect, defaultTitle = "All" } = props;
  const [isAddNameModalShow, setIsAddNameModalShow] = useState(false);
  const contextRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLButtonElement>(null);
  const inputReference = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const selected = fees
    .filter((option: any) => value == option.value)
    .concat(waives.filter((option: any) => value == option.value));
  const filteredFees = fees.filter((option: any) => option.text.toLowerCase().includes(searchText.toLowerCase()));
  const filteredWaives = waives.filter((option: any) => option.text.toLowerCase().includes(searchText.toLowerCase()));

  const onChangeCheckbox = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps, optionValue: string) => {
    onChange(optionValue);
    setOpen(false);
  };

  const getText = () => {
    let str = selected.length === 0 ? defaultTitle : selected[0].text;
    if (str?.length > 30) {
      str = str.substring(0, 27) + "...";
    }
    return str;
  };

  const getCapital = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const onSubmitNewChargesTest = async (data: any) => {
    createFeeitem(data);
    setIsAddNameModalShow(false);
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      const ref: any = inputReference.current;
      ref?.focus();
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (popupRef && popupRef.current) {
        const ref: any = popupRef.current;
        if (contextRef.current?.contains(e.target)) return;
        if (!ref.contains(e.target)) {
          setOpen(false);
        }
      }
    }
  }, []);

  return (
    <>
      <FilterDiv ref={contextRef} onClick={() => setOpen((prevOpen) => !prevOpen)}>
        <p className={selected.length ? "normal" : "hint"}>{getText()}</p>
        <Icon name='caret down' />
      </FilterDiv>
      <AddFeeModal open={isAddNameModalShow} setOpen={setIsAddNameModalShow} onSubmit={onSubmitNewChargesTest} />
      <Popup
        className='filter-dropdown'
        context={contextRef}
        open={open}
        position='bottom left'
        basic
        style={{ padding: 0, marginTop: 0 }}
      >
        <FilterContent ref={popupRef}>
          <Input
            ref={inputReference}
            className='search'
            icon='search'
            placeholder={`Search`}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
          <div className='selectContainer' style={{ maxHeight: 400 }}>
            <p className='small-caption' style={{ marginTop: 12, marginBottom: 4 }}>
              Fees
            </p>
            {filteredFees?.map((option: any, id: number) => (
              <Checkbox
                radio={singleSelect}
                key={id}
                label={<label>{getCapital(option.text)}</label>}
                checked={value == option.value}
                onChange={(e, data) => onChangeCheckbox(e, data, option.value)}
              />
            ))}
            <p className='small-caption'>Discounts</p>
            {filteredWaives?.map((option: any, id: number) => (
              <Checkbox
                radio={singleSelect}
                key={id}
                label={<label>{getCapital(option.text)}</label>}
                checked={value == option.value}
                onChange={(e, data) => onChangeCheckbox(e, data, option.value)}
              />
            ))}
            <Divider className='spacious' />
            <Button
              secondary
              onClick={() => {
                setIsAddNameModalShow(true);
                setOpen(false);
              }}
            >
              Create New Item
            </Button>
          </div>
        </FilterContent>
      </Popup>
    </>
  );
};
