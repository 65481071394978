import { useState, useEffect } from "react";

export function usePersistedState<T>(key: string, defaultValue?: T) {
  const [state, setState] = useState(() => {
    const value = localStorage.getItem(key);
    if (typeof value === "string") {
      try {
        return JSON.parse(value as string);
      } catch (ex) {
        console.log("error while parsing JSON from local storage", ex);
      }
    }
    return defaultValue;
  });
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}
