export function s3Url(payload: any, baseUrl?: string) {
  if (!payload) {
    return null;
  }
  if (baseUrl) {
    const key = payload.children.find((child: any) => {
      return child.name === "Key";
    }).value;
    return `${baseUrl}/${key}`;
  }
  return payload.children.find((child: any) => {
    return child.name === "Location";
  }).value;
}
