import { useMutation, useQueryCache } from "react-query";
import useNetworkHeaders from "../../permits/components/InspectionsCard/useNetworkHeaders";
import { reactivateNotifySubscriptionGroup, ReactivateNotifySubscriptionGroupResponse } from "../../../queries/notify";
import { buildNotifySubscriptionGroupsQueryKey } from "./useNotifySubscriptionGroups";

export function useNotifySubscriptionGroupReactivate({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) {
  const networkHeaders = useNetworkHeaders();
  const queryCache = useQueryCache();

  const [reactivateSubscriptionGroupMutation, { isLoading: isReactivateSubscriptionGroupLoading }] = useMutation(
    reactivateNotifySubscriptionGroup(networkHeaders),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(buildNotifySubscriptionGroupsQueryKey());
        onSuccess();
      },
      onError,
    },
  );

  const reactivateSubscriptionGroup = async (
    id: number,
  ): Promise<ReactivateNotifySubscriptionGroupResponse | undefined> => {
    return await reactivateSubscriptionGroupMutation({ id });
  };

  return { reactivateSubscriptionGroup, isReactivateSubscriptionGroupLoading };
}
