import { useQuery } from "react-query";
import { getNotifySubscriptionGroup, GetNotifySubscriptionGroupResponse } from "../../../queries/notify";
import useNetworkHeaders from "../../permits/components/InspectionsCard/useNetworkHeaders";
import { buildNotifySubscriptionGroupsQueryKey } from "./useNotifySubscriptionGroups";
import { useEffect } from "react";

export function useNotifySubscriptionGroup({
  id,
  onChange = () => {},
}: {
  id: number | null;
  onChange?: (data: GetNotifySubscriptionGroupResponse) => void;
}) {
  const networkHeaders = useNetworkHeaders();
  const { data: subscriptionGroup, isLoading: isSubscriptionGroupLoading } = useQuery(
    buildNotifySubscriptionGroupsQueryKey({ id }),
    getNotifySubscriptionGroup(networkHeaders),
    {
      queryFnParamsFilter(args) {
        return [args[1]];
      },
      enabled: !!id,
    },
  );

  useEffect(() => {
    if (!subscriptionGroup) return;
    onChange(subscriptionGroup);
  }, [subscriptionGroup]);

  return { subscriptionGroup, isSubscriptionGroupLoading };
}
