import decamelize from "decamelize";

export const humanizeString = (input: string) => {
  input = decamelize(input);
  input = input
    .toLowerCase()
    .replace(/[_-]+/g, " ")
    .replace(/\s{2,}/g, " ")
    .trim();
  input = input.charAt(0).toUpperCase() + input.slice(1);
  return input;
};
