import { useMutation, useQueryCache } from "react-query";
import {
  postNotifySubscriptionGroup,
  CreateNotifySubscriptionGroupBody,
  CreateNotifySubscriptionGroupResponse,
} from "../../../queries/notify";
import useNetworkHeaders from "../../permits/components/InspectionsCard/useNetworkHeaders";
import { buildNotifySubscriptionGroupsQueryKey } from "./useNotifySubscriptionGroups";

export function useNotifySubscriptionGroupCreate({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) {
  const networkHeaders = useNetworkHeaders();
  const queryCache = useQueryCache();

  const [createSubscriptionGroupMutation, { isLoading: isCreateSubscriptionGroupLoading }] = useMutation(
    postNotifySubscriptionGroup(networkHeaders),
    {
      onSuccess: () => {
        queryCache.invalidateQueries(buildNotifySubscriptionGroupsQueryKey());
        onSuccess();
      },
      onError,
    },
  );

  const createSubscriptionGroup = async (
    data: CreateNotifySubscriptionGroupBody,
  ): Promise<CreateNotifySubscriptionGroupResponse | undefined> => {
    return await createSubscriptionGroupMutation(data);
  };

  return { createSubscriptionGroup, isCreateSubscriptionGroupLoading };
}
