import React from "react";
import { Link, useParams } from "react-router-dom";

import "./TopNavigation.scss";
import { Dropdown, Label } from "semantic-ui-react";
import { useMedia } from "react-media";
import { isArray } from "lodash";

type Tab = {
  to: string | { pathname: string; search: string };
  label: string;
  activeWhenURLIncludes: string | string[];
  notifications?: number;
};

function makeSiteURl(to: string | { pathname: string; search: string }, site: string) {
  if (typeof to === "object") return to;
  const startsWithSlash = to.startsWith("/");
  const toWithSite = startsWithSlash ? `/${site}${to}` : `/${site}/${to}`;
  return toWithSite;
}

function isActiveTab(tab: Tab) {
  if (isArray(tab.activeWhenURLIncludes)) {
    return tab.activeWhenURLIncludes.some((url) => location.pathname.includes(url));
  }
  return location.pathname.includes(tab.activeWhenURLIncludes);
}

function TopNavigation({ tabs }: { tabs: Tab[] }) {
  const isSmallScreen = useMedia({ query: "(max-width: 567px)" });
  const { site } = useParams<{ site: string }>();

  if (tabs.length >= 3 && isSmallScreen) {
    // Find the active tab, if it's not the first tab
    const activeTabIndex = tabs.findIndex((tab, index) => index !== 0 && isActiveTab(tab));
    // Determine tabs to show: always show the first tab, conditionally show the second or active tab
    const tabsToShow = activeTabIndex > 0 ? [tabs[0], tabs[activeTabIndex]] : tabs.slice(0, 2);
    // Determine tabs to show in the dropdown: all tabs except the ones to show
    const restTabs = tabs.filter((_, index) => !tabsToShow.includes(tabs[index]));

    const restTabsAsDropdownOptions = restTabs.map((tab, index) => ({
      key: index,
      text: tab.label,
      href: makeSiteURl(tab.to, site),
    }));

    return (
      <div className='top-navigation'>
        {tabsToShow.map((tab, index) => (
          <TopNavigation.Link key={index} {...tab} />
        ))}
        <Dropdown className='dropdown-tab tw-whitespace-nowrap' options={restTabsAsDropdownOptions} text='More' />
      </div>
    );
  }

  return (
    <div className='top-navigation'>
      {tabs.map((tab, index) => (
        <TopNavigation.Link key={index} {...tab} />
      ))}
    </div>
  );
}

TopNavigation.Link = function TopNavigationLink(tab: Tab) {
  const { to, label, notifications } = tab;

  const { site } = useParams<{ site: string }>();
  const toWithSite = makeSiteURl(to, site);

  const active = isActiveTab(tab);

  return (
    <div className='tab-container'>
      <Link className={active ? "tab active" : "tab"} to={toWithSite}>
        <span custom-content={label}>
          {label}
          {notifications && (
            <Label size='mini' color='blue' className='notification'>
              {notifications}
            </Label>
          )}
        </span>
      </Link>
      <div className={active ? "tab-active-indicator active" : "tab-active-indicator"}></div>
    </div>
  );
};

export default TopNavigation;
