import React from "react";
import { Flyout } from "../../../../../../components/global_components/Flyout";
import { Button, Form, Header, Icon, Segment } from "semantic-ui-react";
import { useNotifySubscriptionGroup } from "../../../../hooks/useNotifySubscriptionGroup";
import { ControlledInput, ControlledTextArea, ControlledRadioGroup } from "../../../../../../components/ControlledForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNotifySubscriptionGroupEdit } from "../../../../hooks/useNotifySubscriptionGroupUpdate";
import { useToasts } from "react-toast-notifications";
import Tooltip from "../../../../../../components/Tooltip";
import { useSelector } from "react-redux";
import identity from "../../../../../../selectors/identity";
import { useNotifySubscriptionGroupDeactivate } from "../../../../hooks/useNotifySubscriptionGroupDeactivate";
import { useNotifySubscriptionGroupReactivate } from "../../../../hooks/useNotifySubscriptionGroupReactivate";

type EditSubscriptionGroupForm = {
  name: string;
  description: string | null;
  visibility: "Public" | "Private" | "Unlisted";
  autoSubscribe: "Yes" | "No";
};

export function EditSubscriptionGroupFlyout({ id, onClose }: { id: number | null; onClose: () => void }) {
  const toast = useToasts();
  const isCommunicationsAdmin = useSelector(identity.isCommunicationsAdmin);

  const { control, errors, handleSubmit, reset } = useForm<EditSubscriptionGroupForm>({
    defaultValues: {
      name: "",
      description: "",
      visibility: "Public",
      autoSubscribe: "Yes",
    },
  });

  const { subscriptionGroup, isSubscriptionGroupLoading } = useNotifySubscriptionGroup({
    id,
    onChange: (data) => {
      reset({
        autoSubscribe: data.autoSubscribe ? "Yes" : "No",
        description: data.description,
        name: data.name,
        visibility: data.visibility,
      });
    },
  });

  const { editSubscriptionGroup, isEditSubscriptionGroupLoading } = useNotifySubscriptionGroupEdit({
    onSuccess: () => {
      reset();
      onClose();
      toast.addToast("Subscription group updated", { appearance: "success", autoDismiss: true });
    },
    onError: () => {
      toast.addToast("Failed to update subscription group", { appearance: "error", autoDismiss: true });
    },
  });

  const { deactivateSubscriptionGroup, isDeactivateSubscriptionGroupLoading } = useNotifySubscriptionGroupDeactivate({
    onSuccess: () => {
      onClose();
      toast.addToast("Subscription group deactivated", { appearance: "success", autoDismiss: true });
    },
    onError: () => {
      toast.addToast("Failed to deactivate subscription group", { appearance: "error", autoDismiss: true });
    },
  });

  const { reactivateSubscriptionGroup, isReactivateSubscriptionGroupLoading } = useNotifySubscriptionGroupReactivate({
    onSuccess: () => {
      onClose();
      toast.addToast("Subscription group reactivated", { appearance: "success", autoDismiss: true });
    },
    onError: () => {
      toast.addToast("Failed to reactivate subscription group", { appearance: "error", autoDismiss: true });
    },
  });

  const onSave: SubmitHandler<EditSubscriptionGroupForm> = (data) => {
    if (!id) return;
    editSubscriptionGroup(id, {
      name: data.name,
      description: data.description,
      visibility: data.visibility,
      autoSubscribe: data.autoSubscribe === "Yes",
    });
  };

  const isDeactivated = !!subscriptionGroup?.deactivatedAt;
  const shouldDisableMutationButtons =
    isEditSubscriptionGroupLoading ||
    isSubscriptionGroupLoading ||
    isDeactivateSubscriptionGroupLoading ||
    isReactivateSubscriptionGroupLoading ||
    !isCommunicationsAdmin;

  return (
    <Flyout open={!!id} onClose={onClose}>
      <Flyout.Header>
        <div className='tw-flex tw-w-full tw-items-center tw-justify-between'>
          <Header className='tw-mb-0'>Edit Subscription Group</Header>
          {isDeactivated && (
            <Button
              className='button-ghost tw-mr-0'
              disabled={shouldDisableMutationButtons}
              loading={isReactivateSubscriptionGroupLoading}
              onClick={() => {
                if (!id) return;
                reactivateSubscriptionGroup(id);
              }}
            >
              <Icon name='undo' />
              <span>Reactivate</span>
            </Button>
          )}
          {!isDeactivated && (
            <Button
              className='button-ghost negative-bright tw-mr-0'
              disabled={shouldDisableMutationButtons}
              loading={isDeactivateSubscriptionGroupLoading}
              onClick={() => {
                if (!id) return;
                deactivateSubscriptionGroup(id);
              }}
            >
              Deactivate
            </Button>
          )}
        </div>
      </Flyout.Header>
      {isDeactivated && (
        <Segment
          className='tw-m-0 tw-rounded-none tw-border-x-0 tw-border-danger-dark tw-bg-danger tw-text-center'
          attached='bottom'
        >
          <h4 className='tw-text-danger-dark'>This Subscription Group Has Been Deactivated</h4>
        </Segment>
      )}
      <Flyout.Body isLoading={isSubscriptionGroupLoading}>
        <Form>
          <Form.Field required error={!!errors["name"]}>
            <ControlledInput
              control={control}
              name='name'
              label='Name'
              errors={errors}
              rules={{
                validate: {
                  required: (value: string) => {
                    if (!value.trim()) {
                      return "Name is required";
                    }
                    return true;
                  },
                  maxLength: (value: string) => {
                    if (value.trim().length > 191) {
                      return "Name cannot be longer than 191 characters";
                    }
                    return true;
                  },
                },
              }}
              readonly={!isCommunicationsAdmin}
            />
          </Form.Field>
          <Form.Field error={!!errors["description"]}>
            <ControlledTextArea
              control={control}
              name='description'
              label='Description'
              rows={3}
              errors={errors}
              rules={{
                validate: {
                  maxLength: (value: string) => {
                    if (value.trim().length > 191) {
                      return "Description cannot be longer than 191 characters";
                    }
                    return true;
                  },
                },
              }}
              readonly={!isCommunicationsAdmin}
            />
          </Form.Field>
          <Form.Field error={!!errors["visibility"]}>
            <label>
              Visibility
              <Tooltip>
                Private & Unlisted groups will not be visible on the public portal. Subscribers must be manually added
                by staff or a private subscribe link. <br /> Subscribers must log in to the app or website to view the
                list of notifications posted to private groups, but individual message links can be shared with anyone.
              </Tooltip>
            </label>
            <ControlledRadioGroup
              control={control}
              name='visibility'
              options={[
                { key: "Public", text: "Public", value: "Public" },
                { key: "Unlisted", text: "Unlisted", value: "Unlisted" },
                { key: "Private", text: "Private", value: "Private" },
              ]}
              direction='horizontal'
              readonly={!isCommunicationsAdmin}
            />
          </Form.Field>
          <Form.Field error={!!errors["description"]}>
            <label>
              Auto Subscribe
              <Tooltip>
                All subscribers, including anonymous contacts, will be added to this group by default. Anonymous
                subscribers will only receive messages from Auto Subscribe groups.
              </Tooltip>
            </label>
            <ControlledRadioGroup
              control={control}
              name='autoSubscribe'
              options={[
                { key: "Yes", text: "Yes", value: "Yes" },
                { key: "No", text: "No", value: "No" },
              ]}
              direction='horizontal'
              readonly={!isCommunicationsAdmin}
            />
          </Form.Field>
        </Form>
      </Flyout.Body>
      <Flyout.Footer>
        <div className='tw-flex tw-w-full tw-items-center tw-justify-between'>
          <Button className='button-ghost tw-mr-0' onClick={onClose}>
            Cancel
          </Button>
          <Button
            className='tw-mr-0'
            primary
            onClick={handleSubmit(onSave)}
            disabled={shouldDisableMutationButtons}
            loading={isEditSubscriptionGroupLoading}
          >
            Save
          </Button>
        </div>
      </Flyout.Footer>
    </Flyout>
  );
}
