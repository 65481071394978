import { fetch } from "../lib/network";
import decamelizeKeys from "decamelize-keys";
import AppSettings from "../config";

export const postContact = (networkHeaders: any) => (body: any) => {
  return fetch(`${AppSettings.CITIZENS_API_URL}`, {
    method: "POST",
    headers: networkHeaders,
    body: JSON.stringify({ data: { attributes: decamelizeKeys(body, "-") } }),
  }) // Make sure we handle errors
    .then(async (res) => {
      if (res.ok) return res;
      const resText = await res.text();
      throw new Error(resText);
    });
};

export const getContact =
  (networkHeaders: any) =>
  (key: any, id: string, getHistory: boolean = true) => {
    if (id) {
      return fetch(`${AppSettings.CITIZENS_API_URL}/${id}?get_history=${getHistory}`, {
        method: "GET",
        headers: networkHeaders,
      }).then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error(`Query failed with status ${res.status}`);
      });
    } else {
      return null;
    }
  };

export const putContact = (networkHeaders: any, id: string) => (body: any) =>
  fetch(`${AppSettings.CITIZENS_API_URL}/${id}`, {
    method: "PATCH",
    headers: networkHeaders,
    body: JSON.stringify({ data: { attributes: decamelizeKeys(body, "-") } }),
  }) // Make sure we handle errors
    .then((res) => {
      if (res.ok) return res;
      throw new Error(`Query failed with status ${res.status}`);
    });

export const postReactivateContact = (networkHeaders: any, id: string) => () =>
  fetch(`${AppSettings.CITIZENS_API_URL}/reactivate/${id}}`, {
    method: "POST",
    headers: networkHeaders,
  }).then((res) => {
    if (res.ok) return res;
    throw new Error(`Query failed with status ${res.status}`);
  });

export const deleteContact = (networkHeaders: any, id: any) => () =>
  fetch(`${AppSettings.CITIZENS_API_URL}/${id}`, {
    method: "DELETE",
    headers: networkHeaders,
  }) // Make sure we handle errors
    .then((res) => {
      if (res.ok) return res;
      throw new Error(`Query failed with status ${res.status}`);
    });

export const sendForgotPasswordEmail = (networkHeaders: any, email: any) => () =>
  fetch(`${AppSettings.IDENTITY_API_URL}/forgot`, {
    method: "POST",
    headers: networkHeaders,
    body: JSON.stringify({ email }),
  });
